@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.slider-wrapper {
  height: 85px; 
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.slider {
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: slide 10s infinite linear;
}

.slider span {
  display: block;
  font-weight: bold;
  font-size: inherit;
  background: linear-gradient(to right, #8F75EF, #FF9A6C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

@keyframes slide {
  0% { transform: translateY(0%); }
  20% { transform: translateY(-100%); }
  40% { transform: translateY(-200%); }
  60% { transform: translateY(-300%); }
  80% { transform: translateY(-400%); }
  100% { transform: translateY(0%); }
}


@keyframes twinkle {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.twinkle-star {
  animation: twinkle 4s infinite alternate ease-in-out;
}

